'use client'

import 'client-only'

import { Result, Button } from 'antd'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'

function ClientErrorContent({
  error,
  reset
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const router = useRouter()

  useEffect(() => {
    Sentry.captureException(error)
    console.error(error)
  }, [error])

  return (
    <Result
      status="500"
      title="500"
      subTitle={`Sorry, something went wrong (${error.digest} / ${error.message}).`}
      extra={
        <>
          <Button type="primary" onClick={() => { router.push('/') }}>
            Back Home
          </Button>
          &nbsp;or&nbsp;
          <Button type="default" onClick={reset}>
            try again
          </Button>
        </>
      }
    />
  )
}

export default ClientErrorContent
